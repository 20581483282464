* {
  box-sizing: border-box;
}
html, body {
  margin: 0;
  padding: 0;
  font-family:  'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212529;
  color: #F8F8F2;
  max-width: 100%;
  
}
a{
  text-decoration: none;
  color: inherit;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: none;
  outline: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

textarea {
  border: none;
  outline: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

/* neumorphism button */
.neu-button{
  box-shadow: inset -5px -5px 8px #0d0f10,
              inset 5px 5px 8px #353b42;
}
.neu-button:hover {
cursor: pointer;
box-shadow: inset 5px 5px 8px #0d0f10,
            inset -5px -5px 8px #353b42;

}

.neu-div:hover{
  border-radius: 35px;
  background: #212529;
  box-shadow: inset -5px -5px 8px #15181a,
              inset 5px 5px 8px #2d3238;
 
}

.neu-div{
  border-radius: 35px;
  background: #1a1a1a;
  box-shadow: inset 5px 5px 8px #0d0f10,
  inset -5px -5px 8px #2d3238;
  
}

.neu-input{
  /* border-radius: 35px; */
  background-color: #212529;
  color: #F8F8F2;
  box-shadow: inset -5px -5px 8px #15181a,
              inset 5px 5px 8px #2d3238;
 
}

.neu-input:hover{
  /* border-radius: 35px; */
  background-color: #212529;
  color: #F8F8F2;
  box-shadow: inset 5px 5px 8px #15181a,
  inset -5px -5px 8px #2d3238;
  
}


.neu-concave{
  /* border-radius: 50%; */
  box-shadow: inset 5px 5px 8px #15181a,
  inset -5px -5px 8px #2d3238;


}

.neu-concave:hover{
  box-shadow: inset -5px -5px 8px #15181a,
  inset 5px 5px 8px #2d3238;
}



.neu-convex{
  /* border-radius: 51px; */
  /* background: linear-gradient(145deg, #131617, #161a1c); */
  /* border-radius: 80px; */
  background: #212529;
  box-shadow:  10px 10px 20px #15181a,
  inset -10px -10px 20px #2d3238;
}

.neu-convex:hover{
  /* border-radius: 80px; */
  background: #212529;
  box-shadow: inset 5px 5px 8px #15181a,
  inset -5px -5px 8px #2d3238;
}

/* Default: height = 100% */
.heightSize {
  height: 100%;
}

/* For 425px < width < 1440px: disable height */
@media (min-width: 425px) and (max-width: 1439px) {
  .heightSize {
    height: auto; /* Or specify any other value to disable the 100% height */
  }
  .neu-concave {
    box-shadow: none; /* Disable the shadow */
  }
}


